import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { AuthRecoverPassword } from '@luigiclaudio/ga-auth-theme';
import MainContainer from '../components/pageLayout/MainContainer';
import SiteFooter from '../components/pageLayout/SiteFooter';

export default () => {
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                <MainContainer>
                                    {hasMounted && <AuthRecoverPassword buttonSkin="muted" />}
                                </MainContainer>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};
